// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/tmp/7fb7bf9/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/tmp/7fb7bf9/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-first-article-index-js": () => import("/tmp/7fb7bf9/src/pages/my-first-article/index.js" /* webpackChunkName: "component---src-pages-my-first-article-index-js" */),
  "component---src-pages-my-first-article-contents-mdx": () => import("/tmp/7fb7bf9/src/pages/my-first-article/contents.mdx" /* webpackChunkName: "component---src-pages-my-first-article-contents-mdx" */)
}

